/* Burger icon */

#menu-icon {
	display: none;
}

#menu-icon.active {
	position: absolute;
}

#menu-icon {
	position: absolute;
	width: 1.8rem;
	height: 1.8rem;
	top: 18px;
	top: clamp(13px, 3vw, 18px);
	right: 16px;
	right: clamp(1rem, 3vw, 2rem);
	z-index: 31;
	cursor: pointer;
}

#menu-icon > div {
	position: absolute;
	width: 100%;
	/*height: 3px;*/
	height: 0; /* Veggie burger */
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

#menu-icon > div::before, #menu-icon > div::after {
	content: '';
	position: absolute;
	width: 100%;
	height: 3px;
	transition: 150ms ease;
}


#menu-icon > div::before {
	top: -0.35rem;
}

#menu-icon > div::after {
	top: 0.35rem;
}

#menu-icon div, #menu-icon div::before, #menu-icon div::after {
	background: $linkcolor;
	/*transition: 200ms ease;*/
}

#menu-icon.active > div::before {
	top: 0;
	transform: rotate(45deg);
	/*background: $textcolor;*/
}

#menu-icon.active > div::after {
	top: 0;
	transform: rotate(135deg);
	/*background: $linkcolor;*/

}

#menu-icon.active:before {
	opacity: 0;
	transition: opacity 200ms ease;
}

@media only screen and (max-width: 1000px) {
	header #desktop-menu { 
		display: none;
	}
	#menu-icon {
	  display: block; /* Show overlay and burger on mobile devices */
	}
}

/* Menus */


#desktop-menu {
	position: absolute;
	top: 1.5rem;
	right: 1.2rem;
	max-width: 40rem;
	font-weight: 500;
	text-align: right;
	z-index: 20;
}

.mainmenu li, .submenu li {
	display: inline;
	position: relative;
}

.dropdown {
	padding-bottom: 1rem; /* Prevents gap when crossing to the submenu */
}

#desktop-menu .submenu li {
	display: block;
}

#desktop-menu a {
	margin: 0 0.5rem;
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.4;
	white-space: pre;
}

.current, .current:hover {
	background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1), rgba(0, 0, 0, 1));
	background-size: 100% 0.1em;
	background-position: 100% 100%, 0 100%;
	background-repeat: no-repeat;
}

#desktop-menu .instagram-link {
	display: inline-block;
	margin: 0 0.2rem 0 0.8rem;
	position: relative;
	top: 0.05rem;
	left: 0;
	width: 1.3rem;
	height: 1.3rem;
	background-image: $instagram-black;
	background-repeat: no-repeat;
	background-position: left center;
	background-size: auto;
}

.submenu-toggle, .dropdown {
	cursor: pointer;
}

#desktop-menu .submenu-toggle {
		cursor: pointer;
		padding-bottom: 1rem; /* So the pointer doesnt flicker when we enter the dropdown menu. */
	}

.dropdown > a:hover {
	background: none; /* No underline for the submenu parent */
}

/* Desktop menu mechanics */

#desktop-menu .dropdown .submenu {
	line-height: 1.6rem;
	position: absolute;
	right: -0.2rem;
	top: 1.3rem;
	background-color: #fff;
	padding: 0.6rem 0.5rem 0.5rem 0.5rem;
	opacity: 0;
	clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
	transition: all 0.2s ease;
}

#desktop-menu .dropdown:hover .submenu, #desktop-menu .dropdown .submenu:hover {
	opacity: 1;
	clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
	transition: all 0.2s ease;
}

/* Overlay */

.noscroll { overflow: hidden; } /* So content doesn't scroll behind overlay */

/* Setup */

#overlay {   
    position: fixed;
	overflow-x: hidden;
    overflow-y: scroll;
    z-index: 30;
	background-color: #fff;
	width: 100%;
	left: 0;
	top: 0;
	opacity: 0;
	clip-path: polygon(0 0, 100% 0, 100% 0, 0 0); /* Otherwise it's an invisible shield */
}

#overlay #mobile-menu {
	opacity: 0;
	width: 100%;
	height: 100%;
}

/* When hamburger is clicked */

/* First the overlay... */

#overlay.show {
	height: calc(var(--vh, 1vh) * 100); /* Det är ju höjden av det som syns vi ska ha */
	min-height: calc(var(--vh, 1vh) * 100); /* Nödvändigt för att overlay ska täcka hela även när man slår av bookmark bar på iphone */
	animation-name: fadeOverlayIn;
	animation-duration: 200ms;
	animation-fill-mode: forwards;
}

/* Then the overlay content. */

#overlay.show #mobile-menu {
	animation-name: fadeContentIn;
	animation-duration: 500ms;
	animation-fill-mode: forwards;
}

#mobile-menu li {
	margin-bottom: 2.8vh;
}

#mobile-menu a {
	font-size: 1.8rem;
	line-height: 1.4;
	font-weight: 600;
	letter-spacing: -0.02rem;
	cursor: pointer;
}

#mobile-menu .back-link {
	color: #ADB5BD;
	background: 
	linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1)),
	linear-gradient(to right, rgba(173, 181, 189, 1), rgba(173, 181, 189, 1));
		background-size: 100% 0.1em, 0 0.1em;
		background-position: 100% 100%, 0 100%;
		background-repeat: no-repeat;
	transition: background-size 200ms;
}

#mobile-menu .back-link:hover {
	@media (hover: hover) {
		background-size: 0 0.1em, 100% 0.1em;
	}
}

#mobile-menu a:last-child {
	margin-bottom: 0;
}

#mobile-menu .submenu-toggle::after {
	content: "";
	border: solid black;
	border-width: 0 0.12em 0.11em 0;
	display: inline-block;
	padding: 0.3em;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
}

#mobile-menu .back-link::before {
	content: "";
	border: solid #ADB5BD;
	border-width: 0 0.12em 0.11em 0;
	display: inline-block;
	padding: 0.3em;
	transform: rotate(135deg);
	-webkit-transform: rotate(135deg);
}

#mobile-menu .instagram-link {
	position: relative;
    top: 0.2rem;
    left: 0;
	width: 2rem;
	height: 2rem;
	background-image: $instagram-black;
	background-repeat: no-repeat;
	background-position: left center;
	background-size: auto;
}

#mobile-menu .submenu, #mobile-menu .mainmenu {
	display: flex;
	flex-direction: column;
	width: max-content;
	/*max-width: 14rem;*/ /* Lets not have line breaks in the menu */
	position: absolute;
	top: 53%; /* Slightly lower than middle */
	left: 50%;
}

/* Setting up main menu and submenu. */

#mobile-menu .mainmenu {
	transform: translate(-50%, -53%);
}

#mobile-menu .submenu {
	transform: translate(100vw, -53%);
	opacity: 0;
}

/* Switching views. */

#mobile-menu .submenu.show {
	animation-name: slideSubmenuIn;
	animation-duration: 500ms;
	animation-fill-mode: forwards;
}

#mobile-menu .submenu.hide {
	animation-name: slideSubmenuOut;
	animation-duration: 500ms;
	animation-fill-mode: forwards;
}

#mobile-menu .mainmenu.hide {
	animation-name: slideMainmenuOut;
	animation-duration: 500ms;
	animation-fill-mode: forwards;
}

#mobile-menu .mainmenu.show {
	animation-name: slideMainmenuIn;
	animation-duration: 500ms;
	animation-fill-mode: forwards;
}

/* Hiding it all again. */

#overlay:not(.initial).hide {
	animation-name: fadeOverlayOut;
	animation-duration: 400ms;
	animation-fill-mode: forwards;	
}

#overlay:not(.initial).hide #mobile-menu {
	animation-name: fadeContentOut;
	animation-duration: 200ms;
	animation-fill-mode: forwards;
}

@keyframes fadeOverlayIn {
	0% { opacity: 0; clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); height: calc(var(--vh, 1vh) * 100); }
	100% { opacity: 1; clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); height: calc(var(--vh, 1vh) * 100); }
}

@keyframes fadeOverlayOut {
	0% { opacity: 1; clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); height: calc(var(--vh, 1vh) * 100); }
	50% { opacity: 0; clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); height: calc(var(--vh, 1vh) * 100); }
	100% { opacity: 0; clip-path: polygon(0 0, 100% 0, 100% 0, 0 0); }
}

@keyframes fadeContentIn {
	0% { transform: translateY(5%); opacity: 0; }
	20% { transform: translateY(5%); opacity: 0; }
	100% { transform: translateY(0); opacity: 1; }
}

@keyframes fadeContentOut {
	0% { transform: translateY(0); opacity: 1; }
	100% { transform: translateY(5%); opacity: 0; }
}

@keyframes slideMainmenuOut {
	0% { opacity: 1; transform: translate(-50%, -50%); }
	100% { opacity: 0.2; transform: translate(-150vw, -50%); }
}

@keyframes slideSubmenuOut {
	0% { opacity: 1; transform: translate(-50%, -50%); }
	100% { opacity: 0; transform: translate(150vw, -50%); }
}

@keyframes slideMainmenuIn {
	0% { opacity: 0; transform: translate(-150vw, -50%); }
	100% { opacity: 1; transform: translate(-50%, -50%); }
}

@keyframes slideSubmenuIn {
	0% { opacity: 1; transform: translate(150vw, -50%); }
	100% { opacity: 1; transform: translate(-50%, -50%); }
}

@media only screen and (min-width: 1000px) {
	/* These two are in the case the window rezises out of overlay */
	#overlay { 
		display: none;
		animation-name: none;
	}
	.noscroll { overflow: scroll; }
}
