/* Slideshow */

.slideshow {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: clamp(600px, 50vw, 800px);
    z-index: 10;

    &[data-slideshow-nav="manual"] .tile {
        cursor: pointer;
    }

    .prevButton, .nextButton {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: none;
        border: none;
        cursor: pointer;
        z-index: 2; /* Stack arrows above .tile */
    }

    .tile {
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
        height: 100% !important;
        width: 100% !important;
        z-index: 1;
    }

    .image-container {
        width: 100%;
        height: 100%;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover; /* Ensure the image covers the parent container */
        }
    }

    .tile.hide {
        display: none !important; /* Don't show initially */
    }

    .prevButton {
        left: 10px; /* Adjust as needed */
    }

    .nextButton {
        right: 10px; /* Adjust as needed */
    }

    @media (max-width: 600px) {
        .prevButton, .nextButton {
            display: none !important;
        }
    }
}

/* Masonry */

.masonry {
    display: flex;
    flex-direction: row;
    row-gap: clamp(15px, 2vw, 24px);
    /* Placeholder height for CLS */
    min-height: 100vh;

    .col {
        flex: 1;
        margin-right: clamp(15px, 2vw, 24px);
        &:last-child {
            margin-right: 0;
        }
    }

    .col .tile.hide {
        display: none; /* Don't show initially */
    }

    .tile {
        display: block;
        width: 100%;
        margin-bottom: clamp(15px, 2vw, 24px);
        &:last-of-type {
            margin-bottom: 0;
        }
    }

    @media (max-width: 600px) {
        .tile {
            margin: 0 0 15px 0;
        }
    }
}

/* Subway */

.strip-copy {
    width: 100%;
    max-width: 40rem;
    margin-top: clamp(1rem, 3vw, 6rem);
    margin-bottom: clamp(1.5rem, 3vw, 6rem);
}

.strip-copy h1 {
    @include font($headingfont, 700, normal, 2rem, 1.25);
    margin-bottom: 1.5rem;
    max-width: 32rem;
}

.prev-next {
	margin-top: 2rem;
    display: flex;
    justify-content: space-between;
}

@media (max-width: 600px) {
    .prev-next {
        flex-direction: column;

        .next {
            margin-top: 1rem;
            text-align: right;
        }
    }
}

.gallery-description {
    width: 100%;
    max-width: 36rem;
    margin-bottom: clamp(0px, 3vw, 50px);
}

.subway {
    display: flex;
    flex-direction: column;
    row-gap: clamp(15px, 2vw, 24px);
    /* Placeholder height for CLS */
    min-height: 100vh;

    .row {
        display: flex; // Display the tiles in a row
        align-items: flex-start; // Make all items in the row have the same height
        flex-wrap: nowrap; // Prevent the tiles from wrapping onto the next line
    }

    .tile {
        display: flex;
        flex-direction: column;
        margin-right: clamp(5px, 2vw, 24px);
        width: calc(var(--tile-aspect-ratio) * 200vw);
    }

    .tile:last-child {
        margin-right: 0; // Remove the right margin for the last tile
    }

    .row .tile.hide {
        display: none; /* Don't show initially */
    }
}

/* Pagination arrows */

.arrow {
    border: solid black;
    border-width: 0 0.15em 0.15em 0;
    display: inline-block;
    transition: border-color 200ms ease;
    position: relative;
    top: 0.1em;
    width: 1em;
    height: 1em;
}

.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.pagination a:hover .arrow {
    border-color: $link-hover;
    transition: border-color 200ms ease;
}