/* Two-column layout equal width */

article.two-columns-equal {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
	grid-template-rows: auto;
	justify-content: center;
	align-items: end;
	column-gap: 6vw;
	max-width: 50rem;
	margin-top: clamp(1rem, 10vw, 6rem);
}

article.two-columns-equal.large-text {
	grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
	max-width: 70rem;
}

article.two-columns-equal p {
	max-width: none;
}

article.two-columns-equal .image-container {
	margin-bottom: 4.5rem;
}

article.two-columns-equal > div {
	margin-bottom: 4.5rem;
}

.imagecaption p {
	font-size: 0.8rem;
	margin: 0.5rem 0;
}

.two-columns-equal h1 {
	@include font($basefont, 700, normal, 2rem, 1.5);
	margin-bottom: 1.2rem;
}

.two-columns-equal h1:first-child { /* Om h1 kommer först */
	margin-top: 1.5rem;
}

.two-columns-equal h2 {
	@include font($basefont, 700, normal, 1rem, 1.5);
	margin: 2.4rem 0 1.2rem 0;
}

.two-columns-equal li a {
	text-decoration: underline;
	text-decoration-thickness: from-font;
	text-underline-offset: 0.08em;
	font-weight: 500;
}

@media only screen and (max-width: 1000px) {
	.two-columns-equal h1:first-child { /* Om h1 kommer först */
		margin-top: 3rem;
	}
}

@media only screen and (max-width: 600px) {
	article.two-columns-equal, article.two-columns-equal.large-text {
		grid-template-columns: 1fr;
	}
	.two-columns-equal h1:first-child { /* Om h1 kommer först */
		margin-top: 1.5rem;
	}
	.two-columns-equal div:last-child {
		margin-bottom: 1rem;
	}
}