/* one page */

article.page-images-one-text {
	max-width: 40rem;
	margin-top: clamp(1rem, 10vw, 6rem);
}

article.page-images-one-text h1 {
	@include font($headingfont, 800, normal, 2.8rem, 1.1);
	max-width: none;
	margin-bottom: 3rem;
	margin-top: clamp(3rem, 10vw, 6rem);
}

article.page-images-one-text p {
	max-width: none;
}

article.page-images-one-text ol {
	max-width: none;
	font-size: 1.2rem;
	list-style-position: outside;
	list-style-type: decimal;
	margin-bottom: 1.5rem;
}

article.page-images-one-text .page-images-one-text-image {
	max-width: 28rem;
	margin-top: 4.5rem;
}

article.page-images-one-text .contact-info {
	max-width: 28rem;
	margin-top: 3rem;
}

article.page-images-one-text .one-image p {
	font-size: 0.8rem;
	margin-top: 1rem;
}

article.page-images-one-text .contact {
	font-size: 1.8rem;
}

.page-images-one-tiles {
	max-width: 70rem;
	width: 100%;
	margin: 6rem auto 0 auto;
	display: grid;
	grid-template-columns: 3fr 4fr;
	gap: 9rem;
	gap: clamp(4rem, 20vw, 9rem);
}

.page-images-one-tiles .tile:first-child {
	justify-self: center;
	align-self: center;

}

.page-images-one-tiles > *:nth-child(2n-1):nth-last-of-type(1) {
	grid-column: span 2;
	max-width: 60%;
	justify-self: center;
}


@media (max-width: 1000px) {
	article.page-images-one-text {
		margin-top: 1.5rem;
		margin-bottom: 3rem;
	}
}

@media (max-width: 600px) {
	.page-images-one-tiles {
		grid-template-columns: 1fr;
		row-gap: 1rem;
		margin: 2rem 0;
	}
	.page-images-one-tiles .tile {
		width: 100% !important;
		max-width: none !important;
		grid-column: span 1 !important;
	}
	article.page-images-one-text h1 {
		@include font($headingfont, 800, normal, 2.5rem, 1);
		hyphens: auto;
	}
}