/* Sections */

.section-wrapper {
    margin-bottom: 10vw;

    &:last-child {
        margin-bottom: 5vw !important;
    }

    h2, h3, p {
        max-width: 40rem;
    }

    h2 {
        @include font($headingfont, 700, normal, 2rem, 1.25);
        margin-bottom: 1.5rem;
    }

    h3 {
        @include font($headingfont, 700, normal, 1.5rem, 1.25);
        margin-bottom: 1.5rem;
    }
}

@media only screen and (max-width: 600px) {
    .section-wrapper h1 {
        @include font($headingfont, 700, normal, 1.6rem, 1.25);
    }
}

/* Introduction */

#intro, #sassy, #boka {
    max-width: 40rem;
    margin: 0 auto 15vw auto;
}

.intro-text {
    width: calc(100% - 5vw);
    max-width: 48rem;
    background-color: #fff;
    padding: 3vw 4vw;
    position: relative;
    top: -15vw;
    margin-bottom: -15vw;
    left: 100%;
    transform: translateX(-100%);
    box-sizing: border-box;
    z-index: 20;

    h1 {
        @include font($headingfont, 800, normal, 2.4rem, 1.2);
        line-height: clamp(1.1em, 30vw, 1.2em);
        font-size: clamp(2rem, 4vw, 3.2rem);
    }

    p {
        font-size: 1.4rem;
    }
}

#boka .contact {
    font-size: 1.8rem;
    font-weight: 500;

    a {
        text-decoration: none;
        background: 
            linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
            linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1), rgba(0, 0, 0, 1));
        background-size: 100% 0.1em, 0 0.1em;
        background-position: 100% 100%, 0 100%;
        background-repeat: no-repeat;
        transition: background-size 200ms;
    }

    @media (hover: hover) {
        #boka .contact a:hover {
            background-size: 0 0.1em, 100% 0.1em;
        }
    }
}

#menu {
    display: grid;
    grid-template-columns: 1fr 5fr;
    grid-gap: 2rem;
    margin: 5rem 0 3rem 0;

    p {
        margin-bottom: 0;
        max-width: none;
    }

    h2 {
        margin-bottom: 0.5rem;
    }

    .pris {
        font-weight: bold;
        margin: 0;
    }
}

#staggered-images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 60rem;
    margin: 0 auto 15vw auto;
    align-items: center;
    justify-items: end;

    .staggered-image {
        width: 100%;

        &:nth-child(2) {
            width: 67%;
        }
    }
}

#us {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5vw;
    max-width: 60rem;
    margin: 0 auto 15vw auto;
}

.highlight {
    background: #FFF6DA;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    display: inline;
    padding: 0.15rem 0.5rem;
    @include hyphernation(none);
}

#gallerier .gallery {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 3vw;
    margin: 3vw 0 20vw 0;

    a img {
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
        transition: clip-path 0.5s;

        @media (pointer: coarse) {
            clip-path: polygon(0 0, 100% 0%, 100% 65%, 0 70%);
            clip-path: var(--hover-clip-path, polygon(0 0, 100% 0%, 100% 65%, 0 70%));
            transition: clip-path 0.5s;
        }
    }

    a:hover img {
        clip-path: polygon(0 0, 100% 0%, 100% 65%, 0 70%);
        clip-path: var(--hover-clip-path, polygon(0 0, 100% 0%, 100% 65%, 0 70%));
        transition: clip-path 0.3s;
    }


    .gallery .image-container img {
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 100%);
        transition: clip-path 0.3s;
    }
    
}

.gallery .image-container {
    position: relative;
    container-type: inline-size;

    img {
        position: relative;
        z-index: 51;
        display: inline-block;
    }

    h1 {
        position: absolute;
        bottom: 0;
        z-index: 50;
        @include font($basefont, 600, normal, 26px, 1.4);
        font-size: 7cqi;
        margin: 8cqi;
    }

    a h1 {
        opacity: 0;
    }

    a .lazyloaded + h1 {
        opacity: 1;
        transition: opacity 200ms;
        transition-delay: 500ms;
    }
}

.prev-next a {
    font-weight: 500;
    font-size: 1.4rem;
    background: 
        linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
        linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1), rgba(0, 0, 0, 1));
    background-size: 100% 0.1em, 0 0.1em;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
    transition: background-size 200ms;

    @media (hover: hover) {
        &:hover {
            background-size: 0 0.1em, 100% 0.1em;
        }
        color: $textcolor;
    }
}

/* Media Queries */

@media (max-width: 1200px) {
    #gallerier .gallery {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 600px) {
    .section-wrapper h1 {
        @include font($headingfont, 700, normal, 1.6rem, 1.25);
    }

    #intro img {
        object-fit: cover;
        height: 70vw;
        width: 100vw;
    }

    #intro .image-container {
        --aspect-ratio: 1.429 !important;
    }

    #menu {
        grid-template-columns: 1fr;
    }

    #menu p {
        margin-bottom: 2rem;
    }

    #staggered-images {
        grid-template-columns: 1fr;
        row-gap: 5vw;
        justify-items: start;
    }

    .staggered-image {
        width: 100%;
        height: auto; /* Maintain aspect ratio */
        max-height: 100vh; /* Prevent overflow */

        &:nth-child(1) {
            width: 80%;
            max-height: 80vh; /* Prevent overflow */
        }

        &:nth-child(2) {
            width: 60%;
            max-height: 60vh; /* Prevent overflow */
            justify-self: end;
        }
    }

    #gallerier .gallery {
        grid-template-columns: 1fr;
    }

    #us {
        grid-template-columns: 1fr;

        div:nth-child(1) {
            grid-column: 1;
            grid-row: 2;
        }

        div:nth-child(2) {
            grid-column: 1;
            grid-row: 1;
        }
    }
}