$basefont: "degular", sans-serif;
$headingfont: "degular", sans-serif;
$copyfont: "quador", serif;
/*$headingfont: "ff-tisa-sans-web-pro", sans-serif;*/

$grid-width: 100rem;
$article-width: 100rem;
$gallery-base-width: 36rem;
$portrait-width: 38rem;

$textcolor: #000;
$lighttext: #ccc;
$linkcolor: #000;
$link-hover: #52C3BE;