/* Multiple columns */

article.multicol {
	margin-top: clamp(1rem, 10vw, 6rem);
}

article.multicol .columns {
	column-width: 15rem;
	column-count: 4;
	column-gap: 3rem;
	widows: 3;
}

article.multicol .columns p {
	margin: 0;
	padding: 0 0 1.5rem 0;
	-webkit-column-break-inside: avoid;
	page-break-inside: avoid;
	break-inside: avoid;
}

article.multicol h1 {
	@include font($headingfont, 700, normal, 2rem, 1.5);
	margin-bottom: 3rem;
}