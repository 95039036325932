/* Ratio boxes */

.tile, article .image-container {
  width: 100%;
}

.image-container img {
  width: 100%;
  height: auto;
  display: block; /* This removes extra height in div */
  background-color: #f7f7f7;
}

[style*="--aspect-ratio"] > :first-child {
  width: 100%;
}

@supports (--custom:property) {
  [style*="--aspect-ratio"] {
    position: relative;
  }
  [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }  
  [style*="--aspect-ratio"] > :first-child {
    position: absolute;
    top: 0;
  } 
}

.image-container {
  background-color: #faf7f5 !important;
}