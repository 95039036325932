@import 'variables';
@import 'graphics';
@import 'mixins';
@import 'normalize';

/* Main layout. */

html {
	font-size: 19px; /* Set base for rem */
	height: 100%;
	scroll-behavior: smooth;
}

body {
	@include font($basefont, 400, normal, 20px, 1.4);
	color: $textcolor;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	min-height: 100vh;
}

header {
	width: 100%;
	height: 4rem;
	height: clamp(3.5rem, 10vw, 5rem);
	margin: 0 auto;
	flex: 0 0 auto;
	z-index: 20;
}

main {
	margin: 2rem 4vw 4rem 4vw;
	margin: 0 clamp(1rem, 3vw, 1.5rem) clamp(1rem, 3vw, 1.5rem) clamp(1rem, 3vw, 1.5rem);
	flex: 1 0 auto;
	z-index: 10;
}

footer {
	z-index: 2;
	margin: clamp(1rem, 3vw, 2rem);
	margin-bottom: 1rem;
}

article {
	max-width: $article-width;
	margin: 0 auto;
}

article.hello-page {
	margin-bottom: 2rem;
}

/* Header */

#logo {
	position: absolute;
	top: 1.5rem;
	left: 1.5rem;
	left: clamp(0.6rem, 2vw, 1.2rem);
	top: clamp(0.7rem, 3vw, 1.5rem);
	@include font($headingfont, 600, normal, 1.5rem, 1);
}

#logo img {
	height: auto;
	width: 10rem;
}

#logo a:hover {
	background: none;
}

/* Typography */

header {
	font-weight: 500;
}

.logo-link {
	@include font($headingfont, 600, normal, 1.3rem, 1);
}

article p, article ul {
	@include hyphernation(auto);
	hanging-punctuation: first;
	margin-bottom: 1.5rem;
}

footer p {
	@include hyphernation(auto);
	font-size: 0.9em;
	line-height: 1.667em;
	display: block; /* Så p-tagggen bara blir lika vid som innehållet */
	margin: 0;
	max-width: 30rem;
}

strong {
	font-weight: 600;
}

em {
	font-style: italic;
}

b {
	font-weight: inherit;
	background-color: #fcfbc3;
}

ul {
  list-style-type: disc;
  list-style-position: outside;
}

article li {
	margin-left: 1.1rem;
}

footer p {
	max-width: 40rem;
}

/* Links */

a {
	text-decoration: none;
	color: $linkcolor;
	transition: color 200ms ease;
}

main p a, main h1 a, footer p a {
	text-decoration: underline;
	text-decoration-thickness: from-font;
	text-underline-offset: 0.08em;
	font-weight: 500;
}

a:hover {
	color: $textcolor;
}

header a {
	/*padding-bottom: 0.1em;*/
	display: inline-block;
	background: 
		linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
		linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1), rgba(0, 0, 0, 1));
	background-size: 100% 0.1em, 0 0.1em;
	background-position: 100% 100%, 0 100%;
	background-repeat: no-repeat;
	transition: background-size 200ms;
}

header a:hover {
	@media (hover: hover) {
		background-size: 0 0.1em, 100% 0.1em;
	}
	color: $textcolor;
}


.tags a {
	@include font($basefont, 400, italic, 0.9rem, 1.5);
	text-decoration: none;
	@include hyphernation(none);
}

h1.emoji {
	font-size: 6rem!important;
	position: relative;
	left: -0.25rem;
	max-width: none;
	margin: 0 auto 3rem auto;
}

h1.emoji span:first-child {
	position: relative;
	bottom: 0.3rem;
}

.tag-list {
	columns: auto 9rem;
}

img {
	pointer-events: none; /* Crudely makes it harder to right-click and download images. */
	touch-action: none;
}

.copy {
	@include font($copyfont, 400, normal, 1.2rem, 1.5);
	letter-spacing: -0.02em;
}

.large-text {
	font-size: 120%;
}

.large-text.copy {
	font-size: clamp(1.5rem, 5vw, 1.7rem);
	line-height: 1.4;
	letter-spacing: -0.02em;
}


@media (min-width: 600px) {
  h2.large-text {
    font-size: 2.4rem !important;
  }
}
.index-blurb {
	@include font($headingfont, 700, normal, 2rem, 1.25);
	margin: 0 auto 8vw auto;
	width: 100%;
	max-width: 100rem;
}

.index-blurb p {
	max-width: 50rem;
	@include hyphernation(none);
}

@media (min-width: 1000px) {
    .index-blurb {
        font-size: calc(0.009 * 100vw + 27px);
    }
}

@media (max-width: 1000px) {
    .index-blurb {
        margin-bottom: 3rem;
    }
    article {
        margin-top: 0;
    }
    article .image-wrapper {
        grid-template-columns: 1fr;
        max-width: $portrait-width;
    }
}

@media (max-width: 600px) {
    .index-blurb {
        font-size: 1.5rem;
        margin-bottom: 3rem;
    }
    article {
        margin-top: 0;
    }
    h2.grid-headline {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
}

@media (max-width: 320px) {
    .index-blurb {
        font-size: 1.3rem;
        margin-bottom: 3rem;
    }
}

@import 'ratioboxes';
@import 'gallerypages';
@import 'page-images-one';
@import 'two-columns-equal';
@import 'animations';
@import 'menus';
@import 'sections';
@import 'multicol';